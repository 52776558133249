import React, { Suspense, lazy } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled/macro"
import { useCommonItemsQuery } from "../hooks/use-common-items-query"
import { Layout, Theme2, Device, Container } from "../components/Layout"
import { Seo } from "../components/Seo"
import { HeaderMobile } from "../components/HeaderMobile"
import { HeaderDesktop } from "../components/HeaderDesktop"
import { HeroInterior } from "../components/HeroInterior"
import { SidebarForm } from "../components/SidebarForm"
import { InteriorContent } from "../components/InteriorContent"
import { ResultsInterior } from "../components/ResultsInterior"
import { GeneralSchema } from "@ashleynexvelsolutions/general-schema"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import {
  GeneralSchemaFunction,
  PageSpecificSchemaFunction,
} from "../DataImports"
import { ServiceAreasSection } from "../components/ServiceAreasSection"
import AccordionContent from "../components/AccordionContent"
import { BackToTop } from "../components/BackToTop"
import { Helmet } from "react-helmet"

const Footer = lazy(() =>
  import("../components/Footer").then(module => ({
    default: module.Footer,
  }))
)
const BelowFoldContent = lazy(() =>
  import("../components/InteriorContent").then(module => ({
    default: module.InteriorContent,
  }))
)
const BelowFoldServiceAreas = lazy(() =>
  import("../components/ServiceAreasSection").then(module => ({
    default: module.ServiceAreasSection,
  }))
)
const VideoCarousel = lazy(() =>
  import("../components/VideoCarousel").then(module => ({
    default: module.VideoCarousel,
  }))
)
const Awards = lazy(() =>
  import("../components/Awards").then(module => ({
    default: module.Awards,
  }))
)
const InteriorVideoSection = lazy(() =>
  import("../components/InteriorVideoSection").then(module => ({
    default: module.InteriorVideoSection,
  }))
)
const SidebarMenu = lazy(() =>
  import("../components/SidebarMenu").then(module => ({
    default: module.SidebarMenu,
  }))
)
const InteriorCaseStudiesNonPracticeAreas = lazy(() =>
  import("../components/InteriorCaseStudiesNonPracticeAreas").then(module => ({
    default: module.InteriorCaseStudiesNonPracticeAreas,
  }))
)
const InteriorPageCallout = lazy(() =>
  import("../components/InteriorPageCallout").then(module => ({
    default: module.InteriorPageCallout,
  }))
)

const InteriorPageContainer = styled.div`
  display: grid;

  main {
    margin: 5em 0 2em 0;
  }

  @media ${Device.lg} {
    max-width: 1400px;
    margin: 0 auto;
    grid-template-columns: 405px auto;
    grid-column-gap: 10%;
    aside {
      grid-column: 1;
      grid-row: 1;
      margin-top: -4.5em;
      z-index: 1;
    }
    main {
      margin: 5em 0 7em 0;
    }
  }
`
const CaseResultsContainer = styled.div`
  background-color: ${Theme2.colors.tertiary};
  padding: 50px 0;
  color: ${Theme2.colors.light};
  text-align: center;
`
const CaseResultsTitle = styled.div`
  margin-bottom: 30px;
  font-size: 38px;
  line-height: 38px;
  font-family: ${Theme2.fonts.alternateHeadings};

  hr {
    width: 40px;
    height: 5px;
    background-color: ${Theme2.colors.light};
    border: none;
  }
`

const Page = ({ data }) => {
  const featuredImage =
    data.wpPage.featuredImage !== null
      ? data.wpPage.featuredImage.node.localFile.childImageSharp.gatsbyImageData
          .images.sources[1].srcSet
      : ``

  const {
    commonItemsCaseResultSidebarHeading,
    commonItemsPageSubtitle,
    commonItemsInteriorVideoTitle,
    commonItemsInteriorVideoSubtitle,
  } = useCommonItemsQuery()
  const seo = data.wpPage.seo
  return (
    <>
      <GeneralSchema {...GeneralSchemaFunction()} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />

      <Seo data={seo} />
      <Layout>
        <HeaderMobile />
        <HeaderDesktop />
        <HeroInterior
          isItNull={data.wpPage.featuredImage}
          image={featuredImage}
          alt={data.wpPage.interiorpage.bannerSectionImageAltText}
          title={data.wpPage.title}
          subHeading={commonItemsPageSubtitle}
        />

        <Container>
          <InteriorPageContainer>
            <main>
              <InteriorContent
                content={data.wpPage.interiorpage.contentSection1}
              />
              {data.wpPage.interiorpage.contentSection2 && (
                <>
                  {data.wpPage.title === "Review Us" && (
                    <>
                      <Helmet>
                        <script
                          id="reviewScript"
                          src={process.env.GATSBY_BIRDEYE_REVIEW_URL}
                          async
                        />
                      </Helmet>
                      <div id={process.env.GATSBY_BIRDEYE_REVIEW_ID} />
                    </>
                  )}
                  <Suspense fallback="loading">
                    <InteriorVideoSection
                      altText={
                        data.wpPage.interiorpage.interiorVideoImageAltText
                      }
                      title={commonItemsInteriorVideoTitle}
                      subtitle={commonItemsInteriorVideoSubtitle}
                    />
                  </Suspense>
                  <Suspense fallback="loading">
                    <BelowFoldContent
                      content={data.wpPage.interiorpage.contentSection2}
                    />
                  </Suspense>
                </>
              )}
              {data.wpPage.title === "Service Areas" && (
                <ServiceAreasSection isHomePage={false} />
              )}
              {data.wpPage.title === "Courts" && (
                <AccordionContent
                  isHomePage={false}
                  resultsTabTitle="Courts"
                  paddingBottom={true}
                />
              )}
              <Suspense fallback="loading">
                <InteriorPageCallout
                  calloutImageAltText={
                    data.wpPage.interiorpage.calloutImageAltText
                  }
                />
              </Suspense>
            </main>
            <aside>
              <SidebarForm />

              {data.wpPage.categories.nodes.length > 0 ? (
                <CaseResultsContainer>
                  <CaseResultsTitle>
                    {commonItemsCaseResultSidebarHeading}
                    <hr />
                  </CaseResultsTitle>

                  <ResultsInterior
                    data2={data.wpPage.categories.nodes[0].slug}
                  />
                </CaseResultsContainer>
              ) : (
                <Suspense fallback="loading">
                  <InteriorCaseStudiesNonPracticeAreas />
                </Suspense>
              )}
              <Suspense fallback="loading">
                <SidebarMenu />
              </Suspense>
            </aside>
          </InteriorPageContainer>
        </Container>
        <Suspense fallback="loading">
          <VideoCarousel hasH1={false} />
        </Suspense>
        <Suspense fallback="loading">
          <Awards />
        </Suspense>
        {data.wpPage.title !== "Service Areas" && (
          <Suspense fallback="loading">
            <BelowFoldServiceAreas isHomePage={true} />
          </Suspense>
        )}
        <Suspense fallback="loading">
          <Footer />
        </Suspense>
        <BackToTop />
      </Layout>
    </>
  )
}
export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        canonical
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
      interiorpage {
        bannerSectionImageAltText
        contentSection1
        interiorVideoImageAltText
        calloutImageAltText
        contentSection2
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      title
      uri
      categories {
        nodes {
          slug
        }
      }
    }
    file(relativePath: { eq: "interior-cta-background.jpg" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], layout: FULL_WIDTH)
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Page
